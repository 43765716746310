import React from 'react';
import ControlledCustomCheckbox, { ControlledCustomCheckboxProps } from './ControlledCustomCheckbox';

type ControlledCustomSwitchProps = Omit<ControlledCustomCheckboxProps, 'type'>;

const ControlledCustomSwitch = (props: ControlledCustomSwitchProps) => {
  return <ControlledCustomCheckbox {...props} type="switch" />;
};

export default ControlledCustomSwitch;
