import { ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import useAuth from '../auth/useAuth';

const useApolloClientFactory = (apiEndpoint: string) => {
  const { identityToken } = useAuth();

  const client = useMemo(() => {
    const httpLink = createHttpLink({
      uri: apiEndpoint
    });

    const asyncAuthLink = setContext(async (_, { headers }) => {
      const token = await identityToken();
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        }
      };
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (!!graphQLErrors?.length) {
        graphQLErrors.forEach(({ message }) => {
          toast.error(message);
        });
      }
      if (!!networkError) {
        toast.error(`Something went wrong while making the request`);
      }
    });

    return new ApolloClient({
      defaultOptions: {
        query: { fetchPolicy: 'no-cache' },
        watchQuery: { fetchPolicy: 'no-cache' },
        mutate: { fetchPolicy: 'no-cache' }
      },
      link: from([errorLink, asyncAuthLink, httpLink]),
      cache: new InMemoryCache()
    });
  }, [apiEndpoint, identityToken]);

  return client;
};

export default useApolloClientFactory;
