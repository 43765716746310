import React from 'react';
import { Badge } from 'react-bootstrap';
import { DecisionType } from 'apollo/graphql/generated';

interface Props {
  decision: DecisionType;
}

const ResolvedDecisionBadge = ({ decision }: Props) => {
  return (
    <>
      {decision === DecisionType.Removed && (
        <Badge pill bg={'danger'}>
          REMOVED
        </Badge>
      )}
      {decision === DecisionType.Ignored && (
        <Badge pill bg={'success'}>
          IGNORED
        </Badge>
      )}
    </>
  );
};

export default ResolvedDecisionBadge;
