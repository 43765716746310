import React, { useContext } from 'react';
import { Table as BTable } from 'react-bootstrap';
import { useTable } from 'react-table';
import TableContext from '../../contexts/TableContext';
import TablePagination from './pagination/TablePagination';
import LoadingSpinner from 'components/placeholders/LoadingSpinner';
import BodyBlockquoteText from 'components/typography/BodyBlockquoteText';
import ItemsPerPageDropdown from './pagination/ItemsPerPageDropdown';

const NO_RECORDS_TEXT = 'No records found.';

const ReactTable = ({ columns, data, loading = false, pagination = true, noRecordsText = NO_RECORDS_TEXT }) => {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data
  });

  const { errorMessage } = useContext(TableContext);

  // Render the UI for your table
  return (
    <>
      {pagination && (
        <div className="pagination d-flex justify-content-between">
          {/*NOTE: Justify between and adding empty div was the quickest way without converting this to grid */}
          <div />
          <TablePagination />
          <ItemsPerPageDropdown />
        </div>
      )}
      <BTable {...getTableProps()} responsive style={{ borderTop: '1px solid #B8B8B8' }}>
        <thead>
          {headerGroups.map((headerGroup) => {
            const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
            return (
              <tr key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column) => {
                  const { key, ...restColumn } = column.getHeaderProps();
                  return (
                    <th key={key} {...restColumn}>
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>

        <tbody>
          {loading && (
            <tr>
              <td colSpan={10} className="text-center">
                <LoadingSpinner />
              </td>
            </tr>
          )}
          {rows.length === 0 && !loading && (
            <tr>
              <td colSpan={10} className="text-center py-5">
                <BodyBlockquoteText>{noRecordsText}</BodyBlockquoteText>
              </td>
            </tr>
          )}
          {!loading &&
            rows.map((row) => {
              prepareRow(row);
              const { key, ...restRowProps } = row.getRowProps();
              return (
                <tr key={key} {...restRowProps}>
                  {row.cells.map((cell) => {
                    const { key, ...restCellProps } = cell.getCellProps();
                    return (
                      <td key={key} {...restCellProps}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </BTable>
      {errorMessage && <div className="error text-danger py-2 text-center">{errorMessage}</div>}
      {pagination && (
        <div className="pagination mt-4 d-flex justify-content-center">
          <TablePagination />
        </div>
      )}
    </>
  );
};

export default ReactTable;
