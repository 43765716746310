import React from 'react';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import breakpoints from '../styles/breakpoints';

const Header = styled.div`
  padding-top: 30px;
  min-height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #eaeaea;
  border-bottom: 1px solid #b8b8b8;
  @media only screen and (${breakpoints.device.sm}) {
    padding-top: 15px;
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: 16px;
  align-items: center;
  margin-top: 60px;
  @media only screen and (${breakpoints.device.sm}) {
    margin-top: 30px;
  }
`;

const PageTitle = styled.h1`
  margin-bottom: 0;
  color: #233869;
  line-height: 1;
`;

const TitleIcon = styled.img`
  width: 80px;
  height: 80px;
  margin-right: 15px;
  object-fit: contain;
`;

interface PageHeaderProps {
  title: string;
  subtitle?: string | ReactNode;
  button?: ReactNode;
  navigation?: ReactNode;
  backButton?: ReactNode;
  birdCompletion?: ReactNode;
  titleIconUrl?: string;
}

const PageHeader: FC<PageHeaderProps> = ({
  title,
  subtitle,
  button,
  navigation,
  backButton,
  titleIconUrl,
  birdCompletion,
  children
}) => {
  return (
    <Header>
      <Container>{backButton}</Container>
      <Container>
        <StyledRow className="mb-3 align-items-center">
          <Col sm={8}>
            <div className="d-md-flex">
              {!!titleIconUrl && <TitleIcon src={titleIconUrl} />}
              <PageTitle className="mb-0">{title}</PageTitle>
              {children}
              <div className="mb-2 md-mb-0 mt-1 mt-md-0 ms-md-4 d-flex align-items-end text-secondary">{subtitle}</div>
            </div>
          </Col>
          <Col sm={4} className="d-flex justify-content-sm-end">
            {button}
            {birdCompletion}
          </Col>
        </StyledRow>
        {navigation}
      </Container>
    </Header>
  );
};

export default PageHeader;
