import { useFormikContext } from 'formik';
import React from 'react';
import { Prompt } from 'react-router-dom';

const ControlledCustomFormChangePrompt = () => {
  const { dirty } = useFormikContext();

  return (
    <Prompt
      when={dirty}
      message="There were some changes made that are not yet saved. Are you sure you want to leave?"
    />
  );
};

export default ControlledCustomFormChangePrompt;
