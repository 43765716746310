import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import Card from 'components/cards/Card';
import BodyParagraph from 'components/typography/BodyParagraphText';
import BodySmallText from 'components/typography/BodySmallText';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { DecisionType, MediaKind, MediaReportFieldsFragment } from 'apollo/graphql/generated';
import ReportedReasonBadge from 'components/badges/ReportedReasonBadge';
import useMediaReportDecision from 'hooks/reportedMedia/useMediaReportDecision';
import { useNavigate } from 'react-router';
import { formatDate } from 'utils/Helpers';
import { feederLocation } from 'lib/helpers';
import ImagesGallery from '../ImagesGallery';

const ReportedImage = styled.img`
  width: 400px;
  height: 533px;
  object-fit: cover;
  border-radius: 10px;
  cursor: zoom-in;
`;

const Icon = styled.img`
  margin-right: 10px;
`;

type Props = {
  reportedMediaItem: MediaReportFieldsFragment;
  nextItem: { id: string };
};

const ReportedPhotosDetailsCard = ({ reportedMediaItem, nextItem }: Props) => {
  const [galleryOpen, setGalleryOpen] = useState(false);
  const navigate = useNavigate();

  const [removeConfirmationShow, setRemoveConfirmationShow] = useState(false);
  const setMediaReportDecision = useMediaReportDecision();

  const handleRemoveButtonClick = useCallback(() => {
    setRemoveConfirmationShow(true);
  }, []);

  const handleRemoveConfirmationClose = useCallback(() => {
    setRemoveConfirmationShow(false);
  }, []);

  const handleRemoveConfirm = useCallback(async () => {
    const reportedMedia = await setMediaReportDecision(reportedMediaItem.id, DecisionType.Removed);
    if (reportedMedia && nextItem) {
      navigate(`/app/reported-photos/${nextItem.id}`);
    } else if (reportedMedia) {
      navigate('/app/reported-photos/');
    }
  }, [reportedMediaItem.id, setMediaReportDecision, navigate, nextItem]);

  const handleIgnoreButtonClick = useCallback(() => {
    const reportedMedia = setMediaReportDecision(reportedMediaItem.id, DecisionType.Ignored);
    if (reportedMedia && nextItem) {
      navigate(`/app/reported-photos/${nextItem.id}`);
    } else if (reportedMedia) {
      navigate('/app/reported-photos/');
    }
  }, [reportedMediaItem.id, setMediaReportDecision, navigate, nextItem]);

  const handleImageClick = useCallback(() => {
    if (reportedMediaItem.media.kind === MediaKind.Image) {
      setGalleryOpen(true);
    }
  }, [reportedMediaItem.media.kind]);

  const CloseButton = (
    <Button variant="outline-primary" onClick={handleRemoveConfirmationClose}>
      No, I changed my mind
    </Button>
  );

  const RemoveConfirmationButton = (
    <Button variant="danger" onClick={handleRemoveConfirm}>
      Yes, remove it now
    </Button>
  );

  return (
    <Card>
      <div className="d-flex justify-content-start gap-5 w-100 flex-wrap">
        <div className="d-flex align-content-start row">
          {reportedMediaItem.media.kind === MediaKind.Image && (
            <ImagesGallery
              open={galleryOpen}
              setOpen={setGalleryOpen}
              images={[reportedMediaItem.media.url]}
              openedImageIndex={0}
            />
          )}
          <ReportedImage
            onClick={handleImageClick}
            src={
              reportedMediaItem.media.kind === MediaKind.Image
                ? reportedMediaItem.media.url
                : '/static/video-placeholder.jpg'
            }
          />
        </div>
        <div className="d-flex align-content-start gap-4 row col-sm-6">
          <div>
            <BodySmallText>Reason</BodySmallText>
            <div className="d-flex gap-2 flex-wrap">
              {reportedMediaItem.reasons.map((reason, ind) => {
                return <ReportedReasonBadge reason={reason} key={ind} />;
              })}
            </div>
          </div>
          <div>
            <BodySmallText>Nr. of reports for this photo</BodySmallText>
            <BodyParagraph>{reportedMediaItem.numberOfReportsForMedia}</BodyParagraph>
          </div>
          <div>
            <BodySmallText>About photo</BodySmallText>
            <BodyParagraph>
              <Icon src="/static/icons/calendar.svg" alt="calendar icon" width={16} height={16} />
              {formatDate(reportedMediaItem.createdAt)}
            </BodyParagraph>
            <BodyParagraph>
              <Icon src="/static/icons/pin.svg" alt="pin icon" width={16} height={16} />
              {feederLocation(reportedMediaItem.feederLocationCountry, reportedMediaItem.feederLocationCity)}
            </BodyParagraph>
            <BodyParagraph>
              <Icon src="/static/icons/house.svg" alt="house icon" width={16} height={16} />
              {reportedMediaItem.feederName}
            </BodyParagraph>
            {reportedMediaItem.mediaSpecies.map((item) => {
              return (
                <BodyParagraph key={item.id}>
                  {!!item.bird.birdIconAsset && (
                    <Icon
                      src={item.bird.birdIconAsset.url}
                      alt={`${item.bird.speciesCommonName} icon`}
                      width={16}
                      height={16}
                    />
                  )}
                  {item.bird.speciesCommonName} ({item.recognitionType})
                </BodyParagraph>
              );
            })}
          </div>
          <div>
            <BodySmallText>Author</BodySmallText>
            <BodyParagraph>
              <Icon src="/static/icons/person.svg" alt="person icon" />
              {reportedMediaItem.author.username}
            </BodyParagraph>
          </div>
          {reportedMediaItem.media.kind == MediaKind.Video && (
            <div>
              <BodySmallText>Video</BodySmallText>
              <BodyParagraph>
                <a href={reportedMediaItem.media.url} target="_blank" rel="noreferrer">
                  Link to video
                </a>
              </BodyParagraph>
            </div>
          )}
        </div>
      </div>
      {!reportedMediaItem.decision && (
        <div className="d-flex align-items-center justify-content-between mt-4 flex-wrap gap-5">
          <Button variant="success" className="col" onClick={handleIgnoreButtonClick}>
            Looks OK, ignore report
          </Button>
          <Button variant="danger" className="col" onClick={handleRemoveButtonClick}>
            Remove
          </Button>
        </div>
      )}

      <ConfirmationModal
        show={removeConfirmationShow}
        title={'Remove picture from community?'}
        closeHandler={handleRemoveConfirmationClose}
        confirmationHandler={handleRemoveConfirm}
        CloseButton={CloseButton}
        ConfirmationButton={RemoveConfirmationButton}
      >
        <BodyParagraph>Are you sure you want to remove this photo from community? </BodyParagraph>
      </ConfirmationModal>
    </Card>
  );
};

export default ReportedPhotosDetailsCard;
