import { useMediaReportHistoryQuery } from '../../apollo/graphql/generated';
import useGraphError from '../useGraphError';

const useReportedMediaHistory = (id: string) => {
  const { data, loading, error } = useMediaReportHistoryQuery({ variables: { id: id } });
  const errorMessage = useGraphError(error, loading, data?.mediaReportHistory);

  const reportedMediaList =
    data?.mediaReportHistory?.__typename === 'MediaReportListModel' ? data?.mediaReportHistory.mediaReports : undefined;

  return { reportedMediaList, loading, errorMessage };
};

export default useReportedMediaHistory;
