import { useField, useFormikContext } from 'formik';
import React, { PropsWithChildren } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import CustomFormGroup from './CustomFormGroup';

const StyleFormControlFeedback = styled(Form.Control.Feedback)`
  display: block;
`;

type ControlledCustomFormGroupProps = {
  controlId?: string;
  helper?: string;
  label?: string;
  marginBottom?: boolean;
  name: string;
};
const ControlledCustomFormGroup = ({
  controlId,
  children,
  helper,
  label,
  marginBottom = true,
  name
}: PropsWithChildren<ControlledCustomFormGroupProps>) => {
  const { submitCount } = useFormikContext();
  const [, meta] = useField({
    name
  });
  return (
    <CustomFormGroup controlId={controlId || name} marginBottom={marginBottom} helper={helper} label={label}>
      {children}
      {!!meta.error && (submitCount > 0 || meta.touched) && (
        <StyleFormControlFeedback type="invalid">{meta.error}</StyleFormControlFeedback>
      )}
    </CustomFormGroup>
  );
};

export default ControlledCustomFormGroup;
