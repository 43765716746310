import React, { useCallback } from 'react';
import styled, { useTheme } from 'styled-components';
import BodyParagraph from 'components/typography/BodyParagraphText';
import toast from 'react-hot-toast';

const ToastWrapper = styled.div`
  padding: 12px 20px;
  background-color: ${(props) => props.theme.colors.background.danger4};
  border-radius: 4px;
`;

const RemoveReportAlert = (toastId: string) => {
  const theme = useTheme();

  const handleCloseButtonClick = useCallback(() => {
    toast.dismiss(toastId);
  }, [toastId]);

  return (
    <div className="container p-0">
      <ToastWrapper className="d-flex justify-content-between">
        <div className="d-flex justify-content-between gap-2">
          <BodyParagraph fontWeight={theme.fontWeights.bold}>Successfully removed!</BodyParagraph>
          <BodyParagraph>
            We will remove this picture from the community and send it`s author a notification.
          </BodyParagraph>
        </div>
        <img src="/static/icons/close-icon.svg" onClick={handleCloseButtonClick} />
      </ToastWrapper>
    </div>
  );
};

export default RemoveReportAlert;
