import { useFormikContext } from 'formik';
import React, { PropsWithChildren } from 'react';
import { Button } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

type ControlledFormDeleteButtonProps = {
  disabled?: boolean;
  onClick: () => void;
};

const ControlledFormDeleteButton = ({
  children,
  disabled,
  onClick
}: PropsWithChildren<ControlledFormDeleteButtonProps>) => {
  const { isSubmitting } = useFormikContext();

  return (
    <Button variant="outline-danger" onClick={onClick} disabled={isSubmitting ? true : disabled}>
      <span className="inline-flex align-items-center">
        <Trash />
        <span className="ms-2">{children}</span>
      </span>
    </Button>
  );
};

export default ControlledFormDeleteButton;
