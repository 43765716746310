import {
  SoundLicenseCreateInput,
  SoundLicenseFieldsFragment,
  useSoundLicenseCreateMutation
} from 'apollo/graphql/generated';
import { getGraphqlErrorMessage } from 'apollo/helpers';
import { useAlerts } from 'hooks/useAlerts';

const useSoundLicenseCreate = () => {
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const [createSoundLicenseMutation] = useSoundLicenseCreateMutation();

  const createSoundLicense = async (input: SoundLicenseCreateInput): Promise<SoundLicenseFieldsFragment | null> => {
    const { data } = await createSoundLicenseMutation({
      variables: {
        input
      }
    });

    if (!data) {
      return null;
    }

    if (data.soundLicenseCreate.__typename === 'Problem') {
      const errorMessage = getGraphqlErrorMessage(data.soundLicenseCreate);

      addErrorAlert({
        message: errorMessage
      });

      return null;
    }

    addSuccessAlert({
      message: `License "${data.soundLicenseCreate.name}" created.`
    });

    return data.soundLicenseCreate;
  };

  return {
    createSoundLicense
  };
};

export default useSoundLicenseCreate;
