import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import firebaseConfig from './firebaseConfig';
import { AuthContext, useAuthContextData } from './useAuth';

if (!firebase.apps?.length) {
  firebase.initializeApp(firebaseConfig);
}

const AuthProvider: React.FC = ({ children }) => (
  <AuthContext.Provider value={useAuthContextData()}>{children}</AuthContext.Provider>
);

export default AuthProvider;
