import { useFormikContext } from 'formik';
import React from 'react';
import { Button } from 'react-bootstrap';

type FormFooterActionsProps = {
  className?: string;
  deleteButton?: JSX.Element;
};

const FormFooterActions = ({ className = 'd-flex gap-3', deleteButton }: FormFooterActionsProps) => {
  const { dirty, isSubmitting, resetForm } = useFormikContext();

  const onCancelAllChangesClick = () => {
    resetForm();
  };

  return (
    <div className={className}>
      <Button type="submit" variant="primary" disabled={isSubmitting}>
        Save
      </Button>
      {dirty && (
        <Button variant="outline-primary" onClick={onCancelAllChangesClick}>
          Cancel all changes
        </Button>
      )}
      {deleteButton}
    </div>
  );
};

export default FormFooterActions;
