import React from 'react';
import PageHeader from 'components/PageHeader';
import { useNavigate } from 'react-router-dom';
import SoundLicenseForm from 'components/soundLicenses/SoundLicenseForm';
import useSoundLicenseCreate from 'hooks/soundLicense/useSoundLicenseCreate';
import { SoundLicenseCreateInput } from 'apollo/graphql/generated';
import BackButton from 'components/buttons/BackButton';
import PageHead from 'components/PageHead';

const CreateSoundLicense = () => {
  const { createSoundLicense } = useSoundLicenseCreate();
  const navigate = useNavigate();

  const onSoundLicenseSubmit = React.useCallback(
    async (input: SoundLicenseCreateInput) => {
      const newSoundLicense = await createSoundLicense(input);

      setTimeout(() => {
        navigate(`/app/birds/licenses/edit/${encodeURIComponent(newSoundLicense.id)}`);
      }, 500);
    },
    [createSoundLicense, navigate]
  );

  return (
    <>
      <PageHead title="Create a license" />
      <div className="page">
        <PageHeader
          title="Create a license"
          backButton={<BackButton label="All licenses" to="/app/birds/licenses" />}
        />
        <div className="container">
          <SoundLicenseForm onSubmit={onSoundLicenseSubmit} />
        </div>
      </div>
    </>
  );
};

export default CreateSoundLicense;
