import {
  SoundLicenseCreateInput,
  SoundLicenseFieldsFragment,
  useSoundLicenseEditMutation
} from 'apollo/graphql/generated';
import { useAlerts } from 'hooks/useAlerts';
import { getGraphqlErrorMessage } from 'apollo/helpers';

const useSoundLicenseEdit = () => {
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const [editSoundLicenseMutation] = useSoundLicenseEditMutation();

  const editSoundLicense = async (
    id: string,
    input: SoundLicenseCreateInput
  ): Promise<SoundLicenseFieldsFragment | null> => {
    const { data } = await editSoundLicenseMutation({
      variables: {
        id,
        input
      }
    });

    if (!data) {
      return null;
    }

    if (data.soundLicenseEdit.__typename === 'Problem') {
      const errorMessage = getGraphqlErrorMessage(data.soundLicenseEdit);

      addErrorAlert({
        message: errorMessage
      });

      return null;
    }

    addSuccessAlert({
      message: `License "${data.soundLicenseEdit.name}" updated.`
    });

    return data.soundLicenseEdit;
  };

  return {
    editSoundLicense
  };
};

export default useSoundLicenseEdit;
