import { CompletionState, PublishedState } from '../../../apollo/graphql/generated';

export interface TableFilterProps {
  onChange: (filterKey: FilterKey, value: FilterValue) => void;
}

export type FilterValue = string | number | PublishedState | 'PARTIAL' | CompletionState;

export enum FilterKey {
  PublishState = 'publish_state',
  Language = 'language',
  Location = 'location',
  CompletionState = 'completion_state',
  ReportedReasonState = 'reported_reason_state',
  ReportedRecognitionTechniqueState = 'reported_recognition_technique_state',
  RecognizedState = 'recognized_state',
  Country = 'country',
  BirdName = 'bird_name'
}

export type FilterOption = { value: FilterValue; label: string; meta?: { [key: string]: string | number | boolean } };

export type FilterOptions = FilterOption[];
