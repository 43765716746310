import React from 'react';
import styled from 'styled-components';
import BodyParagraph from 'components/typography/BodyParagraphText';
import toast from 'react-hot-toast';

const ToastWrapper = styled.div`
  padding: 12px 20px;
  background-color: ${(props) => props.theme.colors.background.success3};
  border-radius: 4px;
`;

const IgnoreReportAlert = (toastId: string) => {
  const handleCloseButtonClick = () => {
    toast.dismiss(toastId);
  };
  return (
    <div className="container p-0">
      <ToastWrapper className="d-flex justify-content-between">
        <BodyParagraph>Successfully ignored!</BodyParagraph>
        <img src="/static/icons/close-icon.svg" onClick={handleCloseButtonClick} />
      </ToastWrapper>
    </div>
  );
};

export default IgnoreReportAlert;
