import React from 'react';
import styled from 'styled-components';

const StyledText = styled.h5`
  font-family: ${({ theme: { fonts } }) => fonts.helveticaNeue};
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => props.color || props.theme.colors.text.body1};
  @media (max-width: ${(props) => props.theme.deviceSize.mobileL}) {
    font-size: 16px;
  }
`;

type TextProps = {
  color?: string;
};

const H5 = ({ children, color }: React.PropsWithChildren<TextProps>) => {
  return <StyledText color={color}>{children}</StyledText>;
};

export default H5;
