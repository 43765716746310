import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

type CustomFormSubmitProps = {
  onSubmitError: () => void;
};

const CustomFormSubmit = ({ onSubmitError }: CustomFormSubmitProps) => {
  const { isSubmitting, submitCount, isValid } = useFormikContext();
  const [lastHandled, setLastHandled] = useState(0);

  useEffect(() => {
    if (submitCount > lastHandled && !isSubmitting && !isValid) {
      onSubmitError();
      setLastHandled(submitCount);
    }
  }, [submitCount, isValid, isSubmitting, onSubmitError, lastHandled]);

  return null;
};

export default CustomFormSubmit;
