import {
  BirdFragmentFragment,
  SoundLicenseFieldsFragment,
  useSoundLicenseRemoveMutation
} from 'apollo/graphql/generated';
import { getGraphqlErrorMessage } from 'apollo/helpers';
import { useAlerts } from 'hooks/useAlerts';
import { useCallback } from 'react';

const useSoundLicenseRemove = () => {
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const [soundLicenseRemoveMutation, { loading }] = useSoundLicenseRemoveMutation();

  const removeSoundLicense = useCallback(
    async (
      soundLicense: SoundLicenseFieldsFragment
    ): Promise<[removedItems: number, soundLicensesWithBirds: readonly BirdFragmentFragment[]]> => {
      const { data } = await soundLicenseRemoveMutation({
        variables: { id: soundLicense.id }
      });

      if (!data) {
        return [0, []];
      }

      if (data.soundLicenseRemove.__typename === 'Problem') {
        const errorMessage = getGraphqlErrorMessage(data.soundLicenseRemove);

        addErrorAlert({ message: errorMessage });

        return [0, []];
      }

      if (data.soundLicenseRemove.__typename === 'BirdsWithSoundLicense') {
        return [0, data.soundLicenseRemove.birds];
      }

      const numberOfRemovedItems = data.soundLicenseRemove.removedItems;

      addSuccessAlert({
        message: `License ${soundLicense.name} removed`
      });

      return [numberOfRemovedItems, []];
    },
    [soundLicenseRemoveMutation, addErrorAlert, addSuccessAlert]
  );

  return { removeSoundLicense, removeOperation: loading };
};

export default useSoundLicenseRemove;
