import { format, differenceInMinutes, differenceInHours } from 'date-fns';

const formatDate = (date: string) => {
  const now = new Date();
  const hoursDifference = differenceInHours(now, date);
  const minutesDifference = differenceInMinutes(now, date);
  if (minutesDifference > 0 && minutesDifference <= 60) {
    return `${minutesDifference} min`;
  }
  if (hoursDifference > 0 && hoursDifference < 24) {
    return `${hoursDifference}h ago`;
  }
  return `${format(date, 'D. MMM YYYY')} at ${format(date, 'H:mm')}`;
};

export { formatDate };
