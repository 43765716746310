import { Problem } from './graphql/generated';

export const getGraphqlErrorMessage = (dataResult: { __typename?: 'Problem' }): string | null => {
  if (dataResult?.__typename !== 'Problem') {
    return null;
  }

  const { field, kind } = (dataResult as Problem).items[0];

  return `Field: ${field} Kind: ${kind}`;
};
