import React from 'react';
import routes from './routes';
import { useRoutes } from 'react-router-dom';
import AuthProvider from './auth/AuthProvider';
import ApolloClientProvider from './apollo/ApolloProvider';
import AlertsProvider from 'contexts/AlertsProvider';
import ThemeProvider from 'components/theme/Theme';
import 'App.css';

function App() {
  const content = useRoutes(routes);

  return (
    <ThemeProvider>
      <AlertsProvider>
        <AuthProvider>
          <ApolloClientProvider>{content}</ApolloClientProvider>
        </AuthProvider>
      </AlertsProvider>
    </ThemeProvider>
  );
}

export default App;
