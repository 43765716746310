import React from 'react';
import { Button } from 'react-bootstrap';
import { ArrowLeft } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

type BackButtonProps = {
  label: string;
  to: string;
};

const BackButton = ({ label, to }: BackButtonProps) => {
  return (
    <Button variant="outline-primary" style={{ display: 'inline-flex', alignItems: 'center' }} to={to} as={Link}>
      <ArrowLeft /> <span className="ms-2">{label}</span>
    </Button>
  );
};

export default BackButton;
