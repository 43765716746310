import { safeJsonParse } from './string.utils';
import { isEmpty, isNull, isObject } from 'lodash';
import { TableId } from '../contexts/TableContext';

export const getTableStorageKey = (tableId: TableId) => `${tableId}-table-filters`;
export const getTableItemsPerPageKey = () => 'pagination-items-per-page';

export const setLocalStorageValue = <T>(key: string, value: T): void => {
  let valueToStore: string;

  if (isObject(value)) {
    valueToStore = JSON.stringify(value);
  } else {
    valueToStore = value.toString();
  }

  localStorage.setItem(key, valueToStore);
};

export const getLocalStorageObjectValue = <T>(key: string, fallbackValue: T = null): T => {
  const rawValue = localStorage.getItem(key);

  if (isNull(rawValue)) {
    return fallbackValue;
  }

  const value: T = safeJsonParse(rawValue) as T;

  return isEmpty(value) ? fallbackValue : value;
};

export const getLocalStorageNumericValue = (key: string, fallbackValue: number | null = null): number | null => {
  const rawValue = localStorage.getItem(key);
  const value = parseInt(rawValue);

  return isFinite(value) ? value : fallbackValue;
};
