import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import breakpoints from '../../styles/breakpoints';

const StyledFormSection = styled.div`
  border: 3px solid #eaeaea;
  padding: 24px;
  margin-top: 60px;
  margin-bottom: 50px;

  @media only screen and (${breakpoints.device.sm}) {
    margin-top: 40px;
    margin-bottom: 20px;
  }
`;

const StyledTitle = styled.h3`
  margin-bottom: 30px;
  font-weight: 500;
  color: #233869;
`;

interface FormSectionProps {
  title: string;
  children: React.ReactNode;
}

const FormSection: FC<FormSectionProps> = ({ title, children }) => {
  return (
    <StyledFormSection>
      <StyledTitle>{title}</StyledTitle>
      {children}
    </StyledFormSection>
  );
};

export default FormSection;
