import {
  SortBy,
  SortDirection,
  SoundLicenseConnectionModel,
  SoundLicenseFieldsFragment,
  SoundLicenseFilterInput,
  useSoundLicenseListFilteredQuery
} from 'apollo/graphql/generated';
import TableContext from 'contexts/TableContext';
import { useContext, useEffect, useMemo, useState } from 'react';
import useGraphError from 'hooks/useGraphError';
import { UseFilteredQueryType } from 'hooks/types';

const useFilteredSoundLicenses: UseFilteredQueryType<SoundLicenseFieldsFragment> = () => {
  const [entities, setEntities] = useState<SoundLicenseFieldsFragment[]>([]);
  const {
    pagination: { perPage, currentPage, lastPage },
    sorts,
    setPagination
  } = useContext(TableContext);

  const filter = useMemo<SoundLicenseFilterInput>(() => {
    const nameSort = sorts.find((s) => s.key === 'name');

    if (nameSort) {
      return {
        sortBy: SortBy.Name,
        SortDirection: nameSort.direction.toUpperCase()
      };
    }

    return {
      sortBy: SortBy.Time,
      sortDirection: SortDirection.Desc
    };
  }, [sorts]);

  const { data, loading, error } = useSoundLicenseListFilteredQuery({
    variables: { filter, page: currentPage, take: perPage }
  });

  const errorMessage = useGraphError(error, loading, data?.soundLicenseListFiltered);

  useEffect(() => {
    if (loading || !data || data.soundLicenseListFiltered.__typename === 'Problem') {
      return;
    }

    const { edges, pageInfo } = data.soundLicenseListFiltered;

    if (currentPage !== pageInfo.currentPage || lastPage !== pageInfo.lastPage) {
      setPagination({
        currentPage: pageInfo.currentPage,
        lastPage: pageInfo.lastPage,
        perPage
      });
    }

    setEntities(edges.map((edge) => edge.node));
  }, [errorMessage, data, loading, setPagination, perPage, lastPage, currentPage]);

  return {
    loading,
    errorMessage,
    entities,
    allEntitiesCount: (data?.soundLicenseListFiltered as SoundLicenseConnectionModel)?.pageInfo?.allRecords
  };
};

export default useFilteredSoundLicenses;
