import React, { PropsWithChildren } from 'react';
import StickyFooterActions from 'components/StickyFooterActions';

type FormStickyFooterActionsProps = {
  className?: string;
};

const FormStickyFooterActions = ({
  children,
  className = 'd-flex align-items-center gap-2 justify-content-end flex-wrap'
}: PropsWithChildren<FormStickyFooterActionsProps>) => {
  return (
    <StickyFooterActions>
      <div className={className}>{children}</div>
    </StickyFooterActions>
  );
};

export default FormStickyFooterActions;
