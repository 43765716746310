import React, { ComponentType, createContext, PropsWithChildren, useEffect, useReducer } from 'react';
import reducer from './TableReducer';
import { State, TableContextValue, Filter, Sort, SearchQuery, Pagination, ErrorMessage } from './TableTypes';
import { buildQuery, updateUrl, parseUrl } from './TableMethods';
import { useLocation } from 'react-router';
import {
  getLocalStorageObjectValue,
  getTableStorageKey,
  setLocalStorageValue,
  getTableItemsPerPageKey,
  getLocalStorageNumericValue
} from '../utils/browser-storage.utils';
import { isEmpty, isEqual, isNumber } from 'lodash';
import { DEFAULT_TABLE_PAGE_SIZE } from '../components/tables/constants';

const initialState: State = {
  isInitialized: false,
  filters: [],
  sorts: [],
  multipleSortKeys: false,
  search: '',
  pagination: {
    currentPage: 1,
    lastPage: 1,
    perPage: DEFAULT_TABLE_PAGE_SIZE
  },
  urlParams: '',
  errorMessage: ''
};

const TableContext = createContext<TableContextValue>({
  ...initialState
});

export enum TableId {
  Bird = 'bird',
  ManualReviewEscalated = 'manual-review-escalated',
  ManualReviewResolved = 'manual-review-resolved',
  ManualReviewSavedNotNotified = 'manual-review-saved-not-notified',
  ReportedPhotos = 'reported-photos',
  ResolvedPhotos = 'resolved-photos'
}

type TableProviderProps = {
  tableId?: TableId;
  perPage?: number;
  multipleSortKeys?: boolean;
};

const getPersistedFilters = (existingFilters: Filter[], tableId?: TableId): Filter[] => {
  if (isEmpty(tableId)) {
    return existingFilters;
  }

  const key = getTableStorageKey(tableId);
  const persistedFilters = getLocalStorageObjectValue<Filter[]>(key, []);

  if (!isEmpty(existingFilters)) {
    if (!isEqual(existingFilters, persistedFilters)) {
      setLocalStorageValue(key, existingFilters);
    }

    return existingFilters;
  }

  return persistedFilters;
};

const getPersistedPerPage = (): number => {
  const key = getTableItemsPerPageKey();
  let perPage = getLocalStorageNumericValue(key);

  if (!isNumber(perPage)) {
    perPage = initialState.pagination.perPage;
    setLocalStorageValue(key, perPage);
  }

  return perPage;
};

export const TableProvider = ({
  children,
  tableId,
  multipleSortKeys = false
}: PropsWithChildren<TableProviderProps>) => {
  const perPage = getPersistedPerPage();
  const [state, dispatch] = useReducer(reducer, { ...initialState, tableId });
  const location = useLocation();
  const { filters, sorts, search, pagination } = state;

  useEffect(() => {
    const { sorts, page, search, ...urlParams } = parseUrl();
    const filters = getPersistedFilters(urlParams.filters, tableId);

    dispatch({
      type: 'INITIALIZE',
      payload: {
        filters,
        sorts,
        search,
        multipleSortKeys,
        pagination: {
          ...initialState.pagination,
          perPage,
          currentPage: page
        }
      }
    });
  }, [perPage, multipleSortKeys, tableId]);

  useEffect(() => {
    if (!state.isInitialized) return;
    const { filters, sorts, page, search } = parseUrl();
    setPagination({ ...pagination, currentPage: page });
    setFilters(filters);
    setSorts(sorts);
    setSearch(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const urlChangingParams = {
    currentPage: pagination.currentPage,
    filters,
    sorts,
    search
  };

  useEffect(() => {
    if (state.isInitialized) {
      const urlParams = buildQuery({ page: pagination.currentPage, filters, sorts, search });
      if (location.search === '?' + urlParams) return;
      updateUrl(urlParams);
      dispatch({ type: 'SET_URL_PARAMS', payload: { urlParams } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, Object.values(urlChangingParams));

  const setFilters = (filters: Filter[]): void => {
    dispatch({
      type: 'SET_FILTERS',
      payload: {
        filters
      }
    });
  };

  const setSorts = (sorts: Sort[]): void => {
    dispatch({
      type: 'SET_SORTS',
      payload: {
        sorts
      }
    });
  };

  const setSearch = (query: SearchQuery): void => {
    dispatch({
      type: 'SET_SEARCH',
      payload: {
        search: query
      }
    });
  };

  const setPagination = (pagination: Pagination): void => {
    dispatch({
      type: 'SET_PAGINATION',
      payload: {
        pagination
      }
    });
  };

  const setErrorMessage = (message: ErrorMessage): void => {
    dispatch({
      type: 'SET_ERROR_MESSAGE',
      payload: {
        message
      }
    });
  };

  return (
    <TableContext.Provider
      value={{
        ...state,
        setFilters,
        setSorts,
        setSearch,
        setPagination,
        setErrorMessage
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

export const withTableProvider = (Component: ComponentType, tableProviderProps: TableProviderProps = {}) =>
  function TableProvideWrapper() {
    return (
      <TableProvider {...tableProviderProps}>
        <Component />
      </TableProvider>
    );
  };

export default TableContext;
