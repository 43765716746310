import { FilterKey } from '../components/tables/filters/TableFilterTypes';
import { Filter, Sort, SearchQuery } from './TableTypes';
import { getKeyByValue } from '../lib/helpers';

export const multipleParamsSeparator = '|';

interface BuildQueryParams {
  page: number;
  filters: Filter[];
  sorts: Sort[];
  search: SearchQuery;
}

export const buildQuery = ({ page, filters, sorts, search }: BuildQueryParams): string => {
  const params = {};

  if (page && page !== 1) params['page'] = page;
  if (filters?.length > 0) params['filters'] = filters.map((f) => `${f.key}:${f.value}`).join(multipleParamsSeparator);
  if (sorts?.length > 0) params['sorts'] = sorts.map((s) => `${s.key}:${s.direction}`).join(multipleParamsSeparator);
  if (search) params['search'] = search;

  return new URLSearchParams(params).toString();
};

export const updateUrl = (queryParams: string): void => {
  const newRelativePathQuery = window.location.pathname + '?' + queryParams;
  history.pushState(null, '', newRelativePathQuery);
};

const parseUrlFilters = (filterUrlParams: string): Filter[] => {
  const urlFilters = filterUrlParams.split(multipleParamsSeparator);
  return urlFilters.map((urlFilter) => {
    const urlFilterKey = urlFilter.split(':')[0];
    const urlFilterValue = urlFilter.split(':')[1];
    // URL filter key is actually a value in FilterKey type
    const filterKey = getKeyByValue(FilterKey, urlFilterKey);
    return {
      key: FilterKey[filterKey],
      value: urlFilterValue
    };
  });
};

const parseUrlSorts = (sortUrlParams: string): Sort[] => {
  const sorts = sortUrlParams.split(multipleParamsSeparator);
  return sorts.map((sort) => ({
    key: sort.split(':')[0],
    direction: sort.split(':')[1] === 'desc' ? 'desc' : 'asc'
  }));
};

export const parseUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const { filters, sorts, page, search } = {
    filters: urlParams.get('filters'),
    sorts: urlParams.get('sorts'),
    page: urlParams.get('page'),
    search: urlParams.get('search')
  };

  const parameters = {
    filters: filters ? parseUrlFilters(filters) : [],
    sorts: sorts ? parseUrlSorts(sorts) : [],
    page: page ? parseInt(page) : 1,
    search: search || ''
  };

  return { ...parameters };
};
