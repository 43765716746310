import { getGraphqlErrorMessage } from 'apollo/helpers';
import { useCallback } from 'react';
import { MediaReportFieldsFragment, useMediaReportDecideMutation, DecisionType } from '../../apollo/graphql/generated';
import { useAlerts } from 'hooks/useAlerts';
import RemoveReportAlert from 'components/alerts/RemoveReportAlert';
import IgnoreReportAlert from 'components/alerts/IgnoreReportAlert';

const useMediaReportDecision = () => {
  const { addCustomAlert, addErrorAlert } = useAlerts();
  const [mediaReportDecisionMutation] = useMediaReportDecideMutation();

  const setMediaReportDecision = useCallback(
    async (id: string, decision: DecisionType): Promise<MediaReportFieldsFragment> => {
      const { data } = await mediaReportDecisionMutation({
        variables: { id, decision }
      });

      if (data?.mediaReportDecide.__typename === 'Problem') {
        const errorMessage = getGraphqlErrorMessage(data.mediaReportDecide);
        addErrorAlert({
          message: errorMessage
        });

        return null;
      }

      const reportedMedia = data.mediaReportDecide;
      if (decision === DecisionType.Removed) {
        addCustomAlert({
          customComponent: RemoveReportAlert
        });
      }
      if (decision === DecisionType.Ignored) {
        addCustomAlert({
          customComponent: IgnoreReportAlert
        });
      }
      return reportedMedia;
    },
    [addErrorAlert, addCustomAlert, mediaReportDecisionMutation]
  );
  return setMediaReportDecision;
};

export default useMediaReportDecision;
