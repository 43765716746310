import { State, Filter, Sort, SearchQuery, Pagination, ErrorMessage } from './TableTypes';
import { setLocalStorageValue, getTableStorageKey, getTableItemsPerPageKey } from '../utils/browser-storage.utils';

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    sorts: Sort[];
    filters: Filter[];
    search: SearchQuery;
    pagination: Pagination;
    multipleSortKeys: boolean;
  };
};

type SetSortsAction = {
  type: 'SET_SORTS';
  payload: {
    sorts: Sort[];
  };
};

type SetFiltersAction = {
  type: 'SET_FILTERS';
  payload: {
    filters: Filter[];
  };
};

type SetSearchAction = {
  type: 'SET_SEARCH';
  payload: {
    search: SearchQuery;
  };
};

type SetPaginationAction = {
  type: 'SET_PAGINATION';
  payload: {
    pagination: Pagination;
  };
};

type SetUrlParamsAction = {
  type: 'SET_URL_PARAMS';
  payload: {
    urlParams: string;
  };
};

type SetErrorMessageAction = {
  type: 'SET_ERROR_MESSAGE';
  payload: {
    message: ErrorMessage;
  };
};

type Action =
  | InitializeAction
  | SetSortsAction
  | SetFiltersAction
  | SetSearchAction
  | SetPaginationAction
  | SetUrlParamsAction
  | SetErrorMessageAction;

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { filters, sorts, search, pagination, multipleSortKeys } = action.payload;
    return {
      ...state,
      isInitialized: true,
      filters,
      sorts,
      multipleSortKeys,
      search,
      pagination
    };
  },
  SET_FILTERS: (state: State, action: SetFiltersAction): State => {
    const { filters } = action.payload;
    const { pagination } = state;
    const key = getTableStorageKey(state.tableId);

    setLocalStorageValue(key, filters);

    return {
      ...state,
      filters,
      pagination: {
        ...pagination,
        currentPage: 1
      }
    };
  },
  SET_SORTS: (state: State, action: SetSortsAction): State => {
    const { sorts } = action.payload;
    return {
      ...state,
      sorts
    };
  },
  SET_SEARCH: (state: State, action: SetSearchAction): State => {
    const { search } = action.payload;
    const { pagination } = state;
    return {
      ...state,
      search,
      pagination: {
        ...pagination,
        currentPage: 1
      }
    };
  },
  SET_PAGINATION: (state: State, action: SetPaginationAction): State => {
    const { pagination } = action.payload;
    const key = getTableItemsPerPageKey();

    setLocalStorageValue(key, pagination.perPage);

    return {
      ...state,
      pagination
    };
  },
  SET_URL_PARAMS: (state: State, action: SetUrlParamsAction): State => {
    const { urlParams } = action.payload;
    return {
      ...state,
      urlParams
    };
  },
  SET_ERROR_MESSAGE: (state: State, action: SetErrorMessageAction): State => {
    const { message } = action.payload;
    return {
      ...state,
      errorMessage: message
    };
  }
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export default reducer;
