import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BodyParagraph from 'components/typography/BodyParagraphText';

type ArrowButtonProps = {
  label: string;
  to: string;
  direction?: 'right' | 'left';
};

const ArrowButton = ({ label, to, direction = 'left' }: ArrowButtonProps) => {
  return (
    <Button variant="outline-primary" style={{ display: 'inline-flex', alignItems: 'center' }} to={to} as={Link}>
      {direction === 'left' && <img src="/static/icons/chevron-left.svg" alt="chevron left icon" />}
      <BodyParagraph>{label}</BodyParagraph>
      {direction === 'right' && <img src="/static/icons/chevron-right.svg" alt="chevron right icon" />}
    </Button>
  );
};

export default ArrowButton;
