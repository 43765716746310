import { ApolloError } from '@apollo/client/errors';
import { getGraphqlErrorMessage } from 'apollo/helpers';
import { useMemo } from 'react';

const useGraphError = (error: ApolloError, loading: boolean, dataResult: { __typename?: string }) => {
  const errorMessage = useMemo(() => {
    if (!!loading) return '';
    if (!!error) return error.message || 'Something went wrong';

    return getGraphqlErrorMessage(dataResult as { __typename?: 'Problem' });
  }, [dataResult, error, loading]);

  return errorMessage;
};

export default useGraphError;
