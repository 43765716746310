import React from 'react';
import type { FC } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const TabsWrapper = styled.div`
  max-width: 100%;
  @media (max-width: 768px) {
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
  }
`;

const StyledTabNav = styled(Nav)`
  display: inline-flex;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin-bottom: -1px;
  border-bottom: 1px solid #b8b8b8;
  background-color: white;
`;

const tabs = [
  {
    href: '/app/birds',
    key: 'list',
    label: 'List of birds'
  },
  {
    href: '/app/birds/badges',
    key: 'badges',
    label: 'Badges'
  },
  {
    href: '/app/birds/food',
    key: 'food',
    label: 'Food'
  },
  {
    href: '/app/birds/languages',
    key: 'languages',
    label: 'Languages'
  },
  {
    href: '/app/birds/licenses',
    key: 'licenses',
    label: 'License'
  },
  {
    href: '/app/birds/exports',
    key: 'exports',
    label: 'Exports'
  }
];

interface BirdTabProps {
  activeTab: 'list' | 'badges' | 'food' | 'languages' | 'licenses' | 'exports';
}

const BirdsTabNavigation: FC<BirdTabProps> = ({ activeTab }) => {
  return (
    <TabsWrapper>
      <StyledTabNav variant="tabs" defaultActiveKey={activeTab}>
        {tabs.map((tab) => (
          <Nav.Item key={tab.key} style={{ flexShrink: 0 }}>
            <Nav.Link eventKey={tab.key} to={tab.href} as={Link}>
              {tab.label}
            </Nav.Link>
          </Nav.Item>
        ))}
      </StyledTabNav>
    </TabsWrapper>
  );
};

export default BirdsTabNavigation;
