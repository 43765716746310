import React from 'react';
import { Button } from 'react-bootstrap';
import { PlusLg } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

interface AddButtonProps {
  label: string;
  as?: React.ElementType;
  to?: string;
  variant?: string;
}

const AddButton = ({ label, variant = 'primary', as, to }: AddButtonProps) => {
  if (to) {
    as = Link;
  }

  return (
    <Button variant={variant} style={{ display: 'inline-flex', alignItems: 'center' }} as={as} to={to}>
      <PlusLg /> <span className="ms-2">{label}</span>
    </Button>
  );
};

export default AddButton;
