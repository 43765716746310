import React from 'react';
import type { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    html, body, #root {
        height:100%;
    }
`;

interface GuestLayoutProps {
  children?: ReactNode;
}

const GuestLayout: FC<GuestLayoutProps> = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      {children || <Outlet />}
    </>
  );
};

export default GuestLayout;
