import React from 'react';
import { ApolloProvider } from '@apollo/client';
import apolloConfig from './apolloConfig';
import useApolloClientFactory from './useApolloClientFactory';

const ApolloClientProvider: React.FC = ({ children }) => {
  const client = useApolloClientFactory(apolloConfig.apiEndpoint);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloClientProvider;
