import React from 'react';
import BackButton from 'components/buttons/BackButton';
import ArrowButton from 'components/buttons/ArrowButton';
import PageHead from 'components/PageHead';
import BodyParagraph from 'components/typography/BodyParagraphText';

type Props = {
  nextItem?: { id: string };
  previousItem?: { id: string };
  totalCount: number;
  resolved: boolean;
};

const ReportedPhotoDetailsHeader = ({ nextItem, previousItem, totalCount, resolved }: Props) => {
  return (
    <>
      <PageHead title="Reported photo" />
      <div className="header">
        <div className="d-flex align-items-center justify-content-between mt-4 flex-wrap gap-1">
          <div className="d-none d-sm-flex">
            <BackButton
              label="All reported photos"
              to={resolved ? '/app/reported-photos/resolved' : '/app/reported-photos'}
            />
          </div>
          <div className="d-sm-none">
            <BackButton label="All photos" to={resolved ? '/app/reported-photos/resolved' : '/app/reported-photos'} />
          </div>
          <div className="d-flex justify-content-between align-items-center flex-wrap gap-1">
            <div className="d-none d-sm-flex">
              <BodyParagraph>{totalCount} total photos</BodyParagraph>
            </div>
            {!!previousItem && <ArrowButton label="Previous" to={`/app/reported-photos/${previousItem.id}`} />}
            {!!nextItem && <ArrowButton label="Next" direction="right" to={`/app/reported-photos/${nextItem.id}`} />}
          </div>
        </div>
        <div className="d-flex d-sm-none justify-content-end">
          <BodyParagraph>{totalCount} total photos</BodyParagraph>
        </div>
      </div>
    </>
  );
};

export default ReportedPhotoDetailsHeader;
