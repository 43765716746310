import React from 'react';
import styled from 'styled-components';
import Card from 'components/cards/Card';
import BodySmallText from 'components/typography/BodySmallText';
import BodyParagraph from 'components/typography/BodyParagraphText';
import useReportedMediaHistory from 'hooks/reportedMedia/useReportedMediaHistory';
import ReportedReasonBadge from 'components/badges/ReportedReasonBadge';
import ResolvedDecisionBadge from 'components/badges/ResolvedDecisionBadge';
import ErrorMessageText from 'components/ErrorMessageText';
import LoadingSpinner from 'components/placeholders/LoadingSpinner';
import { formatDate } from 'utils/Helpers';

const Icon = styled.img`
  margin-right: 10px;
`;

type Props = {
  reportedMediaId: string;
};

const ReportedPhotosHistoryCard = ({ reportedMediaId }: Props) => {
  const { reportedMediaList, loading, errorMessage } = useReportedMediaHistory(reportedMediaId);
  return (
    <Card title="History">
      {errorMessage && <ErrorMessageText text={errorMessage} />}
      {loading && <LoadingSpinner />}
      <div className="table-responsive">
        <table className="table table-borderless table-sm">
          {!!reportedMediaList && (
            <tbody>
              {reportedMediaList?.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <div className="">
                        <div className="d-flex gap-2 flex-wrap align-items-center">
                          <BodySmallText>Reason: </BodySmallText>
                          {item.reasons.map((reason, ind) => (
                            <ReportedReasonBadge reason={reason} key={ind} />
                          ))}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex gap-3 align-items-center mr-2">
                        {!!item.decision && (
                          <>
                            <div className="text-no-wrap">
                              <BodySmallText>
                                <Icon src="/static/icons/flag.svg" alt="flag icon" /> Decision:{' '}
                              </BodySmallText>
                            </div>
                            <ResolvedDecisionBadge decision={item.decision} />
                          </>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex gap-3 align-items-center mr-2">
                        {!!item.decision && (
                          <>
                            <div className="text-no-wrap">
                              <BodySmallText>Decision by:</BodySmallText>
                            </div>
                            <BodyParagraph>{item.decisionBy.name}</BodyParagraph>
                          </>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex gap-2 align-items-center mr-5">
                        <div className="text-no-wrap m-0">
                          <BodySmallText>Reported by:</BodySmallText>
                        </div>

                        <BodyParagraph>{item.reportedBy.username}</BodyParagraph>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div className="text-no-wrap m-0">
                          <BodyParagraph>{formatDate(item.createdAt)}</BodyParagraph>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </Card>
  );
};

export default ReportedPhotosHistoryCard;
