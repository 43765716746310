import { MediaForReviewKind, MediaReviewFieldsFragment } from 'apollo/graphql/generated';

export const getKeyByValue = (object: Record<string, unknown>, value: string | number) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export function getFirstPropertyValue<T = unknown>(object: Record<string, unknown>): T | null {
  if (typeof object !== 'object') {
    return null;
  }

  const firstProperty = Object.keys(object)[0];

  if (!object[firstProperty]) {
    return null;
  }

  return object[firstProperty] as T;
}

export function findFirstPropertyArrayValue<T = unknown>(object: Record<string, unknown>): T[] | null {
  if (Array.isArray(object)) {
    return object;
  }
  if (typeof object !== 'object') {
    return null;
  }

  const keys = Object.keys(object);

  for (const key of keys) {
    const propertyValue = findFirstPropertyArrayValue<T>(object[key] as Record<string, unknown>);

    if (Array.isArray(propertyValue)) {
      return propertyValue;
    }
  }

  return [];
}

export const feederLocation = (feederCountry: string, feederCity?: string) => {
  return feederCity ? `${feederCity}, ${feederCountry}` : feederCountry;
};

export const feederLatLong = (lat?: number, long?: number) => {
  if (!lat && !long) {
    return null;
  } else if (!lat) {
    return `Long:${long}`;
  } else if (!long) {
    return `Lat:${lat}`;
  } else {
    return `(${lat},${long})`;
  }
};

export const reviewItemImage = (reviewItem: MediaReviewFieldsFragment) => {
  const mediaObj = reviewItem.mediaReviewConnections.find(
    (item) => item.mediaForReviewKind === MediaForReviewKind.MediaToReview
  );
  return mediaObj?.media.url;
};
