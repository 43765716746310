import React from 'react';
import type { FC } from 'react';
import { Button } from 'react-bootstrap';
import { Pencil } from 'react-bootstrap-icons';
import { ElementType } from 'react';
import { Link } from 'react-router-dom';

interface EditButtonProps {
  label: string;
  variant: string;
  as?: ElementType;
  to?: string;
  onClick?: () => void;
}

const EditButton: FC<EditButtonProps> = ({ label, variant = 'outline-primary', as, to, onClick }) => {
  if (to) {
    as = Link;
  }

  return (
    <Button
      variant={variant}
      style={{ display: 'inline-flex', alignItems: 'center' }}
      as={as}
      to={to}
      onClick={onClick}
    >
      <Pencil /> <span className="ms-2">{label}</span>
    </Button>
  );
};

export default EditButton;
