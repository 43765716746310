import React, { useEffect } from 'react';
import type { FC } from 'react';
import NProgress from 'nprogress';

const LoadingScreen: FC = () => {
  useEffect(() => {
    NProgress.start();

    return (): void => {
      NProgress.done();
    };
  }, []);

  return <div style={{ backgroundColor: '#ededed', minHeight: '100vh' }}></div>;
};

export default LoadingScreen;
