import React from 'react';
import type { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import MainNavigation from '../MainNavigation';

interface MainLayoutProps {
  children?: ReactNode;
}

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  return (
    <div>
      <MainNavigation />
      {children || <Outlet />}
    </div>
  );
};

export default MainLayout;
