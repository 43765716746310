import React, { createContext, PropsWithChildren, useMemo } from 'react';
import toast, { Toaster, Toast } from 'react-hot-toast';
import { AlertAnyMessage, AlertsContextState } from './types';

export const AlertsContext = createContext<AlertsContextState>({
  addAlert: () => {
    return '';
  }
});
export const ALERT_DEFAULT_DURATION = 5000;

const AlertsProvider = ({ children }: PropsWithChildren<unknown>) => {
  const contextValue: AlertsContextState = useMemo<AlertsContextState>(
    () => ({
      addAlert: (alert: AlertAnyMessage) => {
        if (alert.alertType === 'danger') {
          toast.error(alert.message, {
            duration: alert.autoHideDuration
          });
        } else if (alert.alertType === 'success') {
          toast.success(alert.message, {
            duration: alert.autoHideDuration
          });
        } else if (alert.alertType === 'custom') {
          toast((t: Toast) => alert.customComponent(t.id), {
            position: 'bottom-center',
            style: {
              maxWidth: '100%',
              width: '100%',
              backgroundColor: 'transparent',
              boxShadow: 'none'
            },
            className: 'custom-toast'
          });
        } else {
          throw new Error('Warning, info are currently not implemented');
        }
      }
    }),
    []
  );

  return (
    <AlertsContext.Provider value={contextValue}>
      <Toaster position="bottom-right" toastOptions={{ duration: ALERT_DEFAULT_DURATION }} />
      {children}
    </AlertsContext.Provider>
  );
};

export default AlertsProvider;
