import React from 'react';

type BooleanColumnProps = {
  value: boolean;
};

const BooleanColumn = ({ value }: BooleanColumnProps) => {
  return <img src={`/static/icons/${value ? 'completed' : 'x-circle'}.svg`} />;
};

export default BooleanColumn;
