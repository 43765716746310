import React from 'react';
import styled from 'styled-components';

const StyledText = styled.p`
  font-family: ${({ theme: { fonts } }) => fonts.helveticaNeue};
  font-size: 20px;
  font-weight: ${(props) => props.style.fontWeight || props.theme.fontWeights.regular};
  line-height: 30px;
  letter-spacing: 0px;
  text-align: center;
  color: ${(props) => props.color || props.theme.colors.forms.focusLine};
  margin: 0px;
  @media (max-width: ${(props) => props.theme.deviceSize.mobileL}) {
    font-size: 16px;
  }
`;

type TextProps = {
  color?: string;
  fontWeight?: number;
};

const BodyBlockquoteText = ({ children, color, fontWeight }: React.PropsWithChildren<TextProps>) => {
  return (
    <StyledText color={color} style={{ fontWeight: fontWeight }}>
      {children}
    </StyledText>
  );
};

export default BodyBlockquoteText;
