import React from 'react';
import type { FC } from 'react';
import * as Yup from 'yup';
import { SoundLicenseCreateInput, SoundLicenseFieldsFragment } from 'apollo/graphql/generated';
import FormSection from 'components/forms/FormSection';
import Divider from 'components/Divider';

import CustomForm from 'components/forms/CustomForm';
import ControlledCustomFormGroup from 'components/forms/ControlledFormGroup';
import ControlledCustomFormInput from 'components/forms/ControlledCustomFormInput';
import FormStickyFooterActions from 'components/forms/FormStickyFooterActions';
import FormFooterActions from 'components/forms/FormFooterActions';
import SoundLicenseFormDeleteButton from './SoundLicenseFormDeleteButton';
import CustomFormGroup from 'components/forms/CustomFormGroup';
import ControlledCustomSwitch from 'components/forms/ControlledCustomSwitch';

const schema = Yup.object().shape({
  name: Yup.string().min(2).required('Enter "License name"'),
  link: Yup.string().min(2).required('Enter "Link to license"')
});

interface SoundLicenseFormProps {
  entity?: SoundLicenseFieldsFragment;
  onSubmit: (entity: SoundLicenseCreateInput) => Promise<void>;
}

const SoundLicenseForm: FC<SoundLicenseFormProps> = ({ entity, onSubmit }) => {
  const onFormSubmit = async ({ link, name, needsAttribution = false }: SoundLicenseFieldsFragment) => {
    await onSubmit({
      link,
      name,
      needsAttribution
    });
  };

  return (
    <CustomForm<SoundLicenseFieldsFragment> initialValues={entity} onSubmit={onFormSubmit} validationSchema={schema}>
      <FormSection title="About license">
        <ControlledCustomFormGroup name="name" label="License name">
          <ControlledCustomFormInput name="name" />
        </ControlledCustomFormGroup>
        <ControlledCustomFormGroup name="link" label="Link to license">
          <ControlledCustomFormInput name="link" />
        </ControlledCustomFormGroup>
        <CustomFormGroup>
          <ControlledCustomSwitch label="This license requires attribution" name="needsAttribution" />
        </CustomFormGroup>
        <Divider />
        <FormFooterActions deleteButton={<SoundLicenseFormDeleteButton soundLicense={entity} />} />
      </FormSection>
      <FormStickyFooterActions>
        <FormFooterActions deleteButton={<SoundLicenseFormDeleteButton soundLicense={entity} />} />
      </FormStickyFooterActions>
    </CustomForm>
  );
};

export default SoundLicenseForm;
