import React from 'react';
import styled from 'styled-components';

const StyledDivider = styled.div`
  background-color: #eaeaea;
  height: 2px;
`;

type DividerProps = {
  marginBottom?: number;
  marginTop?: number;
};

const Divider = ({ marginBottom = 4, marginTop = 4 }: DividerProps) => {
  let className = '';

  if (marginBottom) {
    className += `mb-${marginBottom}`;
  }

  if (marginTop) {
    className += ` mt-${marginTop}`;
  }

  return (
    <div className={className}>
      <StyledDivider />
    </div>
  );
};

export default Divider;
