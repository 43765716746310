import { createContext, useMemo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import firebase from 'firebase/app';
import { useContext } from 'react';

export interface AuthContextData {
  login: (email: string, password: string) => Promise<void>;
  loggedIn: boolean;
  identityToken: () => Promise<string>;
  logOut: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  authInitialized: boolean;
}

export const AuthContext = createContext<AuthContextData>(undefined);

export const useAuthContextData = (): AuthContextData => {
  const [firebaseUser, setFirebaseUser] = useState<firebase.User>(firebase.auth().currentUser);
  const [authInitialized, setAuthInitialized] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((_firebaseUser: firebase.User) => {
      setFirebaseUser(_firebaseUser);
      if (!authInitialized) {
        setAuthInitialized(true);
      }
    });
  }, [authInitialized]);

  const loggedIn = useMemo(() => {
    return !!firebaseUser;
  }, [firebaseUser]);

  const login = useCallback(async (email: string, password: string) => {
    await firebase.auth().signInWithEmailAndPassword(email, password);
  }, []);

  const logOut = useCallback(async () => {
    await firebase.auth().signOut();
  }, []);

  const resetPassword = useCallback(async (email: string) => {
    await firebase.auth().sendPasswordResetEmail(email);
  }, []);

  const identityToken = useCallback(async () => {
    return firebaseUser?.getIdToken();
  }, [firebaseUser]);

  return useMemo(() => {
    return {
      loggedIn,
      login,
      logOut,
      resetPassword,
      authInitialized,
      identityToken
    };
  }, [loggedIn, login, logOut, resetPassword, authInitialized, identityToken]);
};

const useAuth = () => {
  const authContext = useContext(AuthContext);

  return authContext;
};

export default useAuth;
