import React from 'react';
import { useField, useFormikContext } from 'formik';
import CustomFormInput, { FormControlType } from './CustomFormInput';

type ControlledCustomFormInputProps = {
  disabled?: boolean;
  name: string;
  placeholder?: string;
  type?: FormControlType;
};

const ControlledCustomFormInput = ({ disabled, name, placeholder, type }: ControlledCustomFormInputProps) => {
  const { submitCount } = useFormikContext();
  const [field, meta] = useField({
    name
  });

  return (
    <CustomFormInput
      disabled={disabled}
      isInvalid={(meta.touched || submitCount > 0) && !!meta.error}
      name={name}
      onChange={field.onChange}
      placeholder={placeholder}
      type={type}
      value={field.value || ''}
    />
  );
};

export default ControlledCustomFormInput;
