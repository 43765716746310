import React from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import H5 from 'components/typography/H5';

interface ConfirmationModalProps {
  show: boolean;
  title: string;
  children: ReactNode;
  confirmationHandler: () => void;
  closeHandler: () => void;
  CloseButton?: ReactNode;
  ConfirmationButton?: ReactNode;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  show,
  title,
  children,
  confirmationHandler,
  closeHandler,
  CloseButton,
  ConfirmationButton
}) => {
  return (
    <Modal show={show} centered onHide={closeHandler}>
      <Modal.Header closeButton>
        <div className="d-flex justify-content-center w-100">
          <H5>{title}</H5>
        </div>
      </Modal.Header>

      <Modal.Body>{children}</Modal.Body>

      <Modal.Footer>
        {CloseButton ? (
          CloseButton
        ) : (
          <Button variant="outline-secondary" onClick={closeHandler}>
            Close
          </Button>
        )}

        {ConfirmationButton ? (
          ConfirmationButton
        ) : (
          <Button variant="primary" onClick={confirmationHandler}>
            Confirm
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  children: PropTypes.node
};

export default ConfirmationModal;
