import React from 'react';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';
import breakpoints from '../styles/breakpoints';

const StickyFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px 15px;
  background-color: #eaeaea;
  display: none;

  @media only screen and (${breakpoints.device.sm}) {
    display: block;
  }
`;

interface StickyFooterProps {
  children: ReactNode;
}

const StickyFooterActions: FC<StickyFooterProps> = ({ children }) => {
  return (
    <StickyFooter>
      <div className="container">{children}</div>
    </StickyFooter>
  );
};

export default StickyFooterActions;
