import React, { useContext, useCallback, Fragment } from 'react';
import { Dropdown } from 'react-bootstrap';
import type { FC } from 'react';
import TableContext from '../../../contexts/TableContext';
import styled from 'styled-components';

const Label = styled.div`
  //font-size: 12px;
  color: #8f8f8f;
  margin-right: 5px;
`;

interface DropdownOption {
  value: number;
  label: string;
}

const options: DropdownOption[] = [
  {
    value: 50,
    label: '50'
  },
  {
    value: 100,
    label: '100'
  },
  {
    value: 200,
    label: '200'
  }
];

const ItemsPerPageDropdown: FC = () => {
  const { pagination, setPagination } = useContext(TableContext);

  const handleChange = useCallback(
    (newPerPage: string) => {
      setPagination({ ...pagination, perPage: parseInt(newPerPage) });
    },
    [pagination, setPagination]
  );

  return (
    <div className="mb-4 d-flex flex-row align-items-center justify-content-center">
      <Label>Items:</Label>
      <Dropdown onSelect={(value: string) => handleChange(value)}>
        <Dropdown.Toggle variant="outline-secondary">{pagination.perPage}</Dropdown.Toggle>
        <Dropdown.Menu>
          {options.map((option) => (
            <Fragment key={option.value}>
              <Dropdown.Item eventKey={option.value.toString()}>{option.label}</Dropdown.Item>
            </Fragment>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default ItemsPerPageDropdown;
