import React, { useContext } from 'react';
import type { FC, ReactNode } from 'react';
import TableContext from '../../../contexts/TableContext';
import SortIndicator from './SortIndicator';

type SortableColumnProps = {
  children: ReactNode | string;
  sortKey: string;
};

const SortableColumn: FC<SortableColumnProps> = ({ children, sortKey }) => {
  const { setSorts, sorts, multipleSortKeys } = useContext(TableContext);
  const currentSort = sorts.find((s) => s.key === sortKey);

  const handleSortChange = () => {
    // If sort with specified sortKey doesnt exist in table's sorts, set "asc" direction with this sortKey
    // If it exists and direction is "asc", set "desc" direction to this sortKey
    // If it exists and direction is "desc", remove from sorts with this sortKey
    if (currentSort) {
      const filteredCurrentSorts = multipleSortKeys ? [...sorts].filter((s) => s.key !== sortKey) : [];
      if (currentSort.direction === 'asc') {
        setSorts([...filteredCurrentSorts, { key: sortKey, direction: 'desc' }]);
      } else {
        setSorts([...filteredCurrentSorts]);
      }
    } else {
      setSorts(
        multipleSortKeys ? [...sorts, { key: sortKey, direction: 'asc' }] : [{ key: sortKey, direction: 'asc' }]
      );
    }
  };

  return (
    <span onClick={handleSortChange} className="cursor-pointer">
      {children}
      <span className="ms-2">
        <SortIndicator direction={currentSort?.direction || null} />
      </span>
    </span>
  );
};

export default SortableColumn;
