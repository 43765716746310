import React, { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import GuestGuard from './components/GuestGuard';
import MainLayout from './components/layouts/MainLayout';
import GuestLayout from './components/layouts/GuestLayout';
import LoadingScreen from './components/LoadingScreen';
import AuthGuard from './components/AuthGuard';
import SoundLicenseList from 'pages/soundLicenses/SoundLicenseList';
import CreateSoundLicense from 'pages/soundLicenses/CreateSoundLicense';
import EditSoundLicense from 'pages/soundLicenses/EditSoundLicense';
import ReportedPhotoDetails from 'pages/reportedPhotos/ReportedPhotoDetails';

function Loadable(Component) {
  return function RouteComponent(props) {
    return (
      <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
      </Suspense>
    );
  };
}

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/auth/Login')));

// Error pages
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));

// Birds pages
const BirdList = Loadable(lazy(() => import('./pages/birds/BirdList')));
const CreateBirdPage = Loadable(lazy(() => import('./pages/birds/CreateBirdPage')));
const EditBirdPage = Loadable(lazy(() => import('./pages/birds/EditBirdPage')));
const BadgeList = Loadable(lazy(() => import('./pages/badges/BadgeList')));
const FoodList = Loadable(lazy(() => import('./pages/food/FoodList')));
const CreateFoodPage = Loadable(lazy(() => import('./pages/food/CreateFoodPage')));
const EditFoodPage = Loadable(lazy(() => import('./pages/food/EditFoodPage')));
const LanguageList = Loadable(lazy(() => import('./pages/languages/LanguageList')));
const CreateLanguage = Loadable(lazy(() => import('./pages/languages/CreateLanguage')));
const EditLanguage = Loadable(lazy(() => import('./pages/languages/EditLanguage')));
const BadgeCreatePage = Loadable(lazy(() => import('./pages/badges/BadgeCreatePage')));
const BadgeEditPage = Loadable(lazy(() => import('./pages/badges/BadgeEditPage')));
const AdminsList = Loadable(lazy(() => import('./pages/admins/AdminList')));
const AdminCreatePage = Loadable(lazy(() => import('./pages/admins/AdminCreatePage')));
const AdminEditPage = Loadable(lazy(() => import('./pages/admins/AdminEditPage')));
const ExportListPage = Loadable(lazy(() => import('./pages/export/ExportList')));
const InferencePage = Loadable(lazy(() => import('./pages/inference/Inference')));
const ReportedPhotosListPage = Loadable(lazy(() => import('./pages/reportedPhotos/PhotosList')));
const ResolvedPhotosListPage = Loadable(lazy(() => import('./pages/reportedPhotos/ResolvedPhotosList')));
const ManualReviewPage = Loadable(lazy(() => import('./pages/manualReview/ManualReviewPhotosList')));
const ManualReviewResolvedPage = Loadable(lazy(() => import('./pages/manualReview/ManualReviewResolvedPhotosList')));
const ManualReviewSavedNotNotifiedPage = Loadable(
  lazy(() => import('./pages/manualReview/ManualReviewSavedNotNotified'))
);
const ManualReviewEscalatedPage = Loadable(lazy(() => import('./pages/manualReview/ManualReviewEscalated')));
const ManualReviewDetailsPage = Loadable(lazy(() => import('./pages/manualReview/ManualReviewDetails')));

const routes: PartialRouteObject[] = [
  {
    path: 'app',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="/app/birds" replace />
      },
      {
        path: '/birds',
        element: <BirdList />
      },
      {
        path: '/birds/create',
        element: <CreateBirdPage />
      },
      {
        path: '/birds/edit/:id',
        element: <EditBirdPage />
      },
      {
        path: '/birds/badges',
        element: <BadgeList />
      },
      {
        path: '/birds/food',
        element: <FoodList />
      },
      {
        path: '/birds/food/create',
        element: <CreateFoodPage />
      },
      {
        path: '/birds/food/edit/:id',
        element: <EditFoodPage />
      },
      {
        path: '/birds/languages',
        element: <LanguageList />
      },
      {
        path: '/birds/languages/create',
        element: <CreateLanguage />
      },
      {
        path: '/birds/languages/edit/:id',
        element: <EditLanguage />
      },
      {
        path: '/birds/licenses',
        element: <SoundLicenseList />
      },
      {
        path: '/birds/licenses/create',
        element: <CreateSoundLicense />
      },
      {
        path: '/birds/licenses/edit/:id',
        element: <EditSoundLicense />
      },
      {
        path: '/birds/badges/create',
        element: <BadgeCreatePage />
      },
      {
        path: '/birds/badges/edit/:id',
        element: <BadgeEditPage />
      },
      {
        path: '/birds/exports',
        element: <ExportListPage />
      },
      {
        path: '/admins',
        element: <AdminsList />
      },
      {
        path: '/admins/create',
        element: <AdminCreatePage />
      },
      {
        path: '/admins/edit/:id',
        element: <AdminEditPage />
      },
      {
        path: '/inference',
        element: <InferencePage />
      },
      {
        path: '/reported-photos',
        element: <ReportedPhotosListPage />
      },
      {
        path: '/reported-photos/resolved',
        element: <ResolvedPhotosListPage />
      },
      {
        path: '/reported-photos/:id',
        element: <ReportedPhotoDetails />
      },
      {
        path: '/manual-review',
        element: <ManualReviewPage />
      },
      {
        path: '/manual-review/resolved',
        element: <ManualReviewResolvedPage />
      },
      {
        path: '/manual-review/saved-and-not-notified',
        element: <ManualReviewSavedNotNotifiedPage />
      },
      {
        path: '/manual-review/escalated',
        element: <ManualReviewEscalatedPage />
      },
      {
        path: '/manual-review/:id',
        element: <ManualReviewDetailsPage />
      }
    ]
  },
  {
    path: '*',
    element: <GuestLayout />,
    children: [
      {
        path: '/',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
