import React from 'react';
import PageHeader from 'components/PageHeader';
import BirdsTabNavigation from 'components/birds/BirdsTabNavigation';
import { withTableProvider } from 'contexts/TableContext';
import useFilteredSoundLicenses from 'hooks/soundLicense/useFilteredSoundLicenses';
import PageHead from 'components/PageHead';
import SoundLicenseListTable from 'components/soundLicenses/SoundLicenseListTable';
import AddButton from 'components/buttons/AddButton';

const SoundLicenseList = () => {
  const { allEntitiesCount, entities, loading } = useFilteredSoundLicenses();

  return (
    <>
      <PageHead title="Licenses" />
      <div className="page">
        <PageHeader
          title="Licenses"
          subtitle={allEntitiesCount > 0 ? <div className="me-3">{allEntitiesCount} licenses</div> : undefined}
          button={<AddButton label="Add license" to="/app/birds/licenses/create" />}
          navigation={<BirdsTabNavigation activeTab="licenses" />}
        />
        <SoundLicenseListTable entities={entities} loading={loading} />
      </div>
    </>
  );
};

export default withTableProvider(SoundLicenseList);
