import React from 'react';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NotFound: FC = () => {
  return (
    <>
      <Helmet>
        <title>Error: Not Found | BirdBuddy</title>
      </Helmet>
      <div className="container py-4 text-center">
        <h4>Page not found</h4>
        <div className="mt-4">
          <Button as={Link} to="/app">
            Back home
          </Button>
        </div>
      </div>
    </>
  );
};

export default NotFound;
