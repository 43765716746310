import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BirdFragmentFragment, SoundLicenseFieldsFragment } from 'apollo/graphql/generated';

const StyledTitle = styled.h5`
  margin-bottom: 0;
  font-weight: 500;
`;

type BirdsWithSoundLicenseModalProps = {
  birds: readonly BirdFragmentFragment[];
  soundLicense: SoundLicenseFieldsFragment;
  onClose: () => void;
};

/**
 * @todo Alter edit link of bird
 */
const BirdsWithSoundLicenseModal = ({
  onClose,
  birds: birdsWithBadge,
  soundLicense: badge
}: BirdsWithSoundLicenseModalProps) => {
  return (
    <Modal show={true} centered onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <div className="flex-fill"></div>
        <StyledTitle>{`Cannot delete ${badge.name}`}</StyledTitle>
        <div className="flex-fill"></div>
      </Modal.Header>
      <Modal.Body>
        <p>This license is already added to the following birds:</p>
        <ul>
          {birdsWithBadge.map(({ speciesCommonName, id }) => (
            <Link to={`/app/birds/edit/${id}`} key={id}>
              <li>{speciesCommonName}</li>
            </Link>
          ))}
        </ul>
        <p>The license cannot be deleted until it is not removed from those birds.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onClose}>
          OK, I understand
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BirdsWithSoundLicenseModal;
