import React from 'react';
import type { FC } from 'react';
import { ArrowDown, ArrowUp } from 'react-bootstrap-icons';

type SortIndicatorProps = {
  direction: 'asc' | 'desc' | null;
};

const SortIndicator: FC<SortIndicatorProps> = ({ direction }) => {
  return (
    <>
      {direction === 'desc' && <ArrowDown />}
      {direction === 'asc' && <ArrowUp />}
    </>
  );
};

export default SortIndicator;
