import React from 'react';
import SortableColumn from 'components/tables/sorts/SortableColumn';
import ReactTable from 'components/tables/ReactTable';

import NavigateActionColumn from 'components/tables/columns/NavigateActionColumn';
import { SoundLicenseFieldsFragment } from 'apollo/graphql/generated';
import { ListTableComponentProps } from 'components/types';
import CenteredColumn from 'components/tables/columns/CenteredColumn';
import BooleanColumn from 'components/tables/columns/BooleanColumn';

const SoundLicenseListTable = ({ entities, loading }: ListTableComponentProps<SoundLicenseFieldsFragment>) => {
  const columns = React.useMemo(
    () => [
      {
        id: 'name',
        Header: <SortableColumn sortKey="name">License</SortableColumn>,
        accessor: function SoundLicenseNameCol(license: SoundLicenseFieldsFragment) {
          return (
            <div className="d-flex align-items-center">
              <div className="name">
                <div className="primary">{license.name}</div>
              </div>
            </div>
          );
        }
      },
      {
        id: 'needsAttribution',
        Header: <div className="text-center">Needs attribution</div>,
        accessor: function SoundLicenseNeedsAttributionCol(license: SoundLicenseFieldsFragment) {
          return (
            <CenteredColumn>
              <BooleanColumn value={license.needsAttribution} />
            </CenteredColumn>
          );
        }
      },
      {
        Header: 'Actions',
        accessor: function SoundLicenseActionColumn(license: SoundLicenseFieldsFragment) {
          return <NavigateActionColumn to={`/app/birds/licenses/edit/${license.id}`} />;
        }
      }
    ],
    []
  );

  return (
    <div className="container">
      <div className="results mt-4">
        <ReactTable columns={columns} data={entities} loading={loading} />
      </div>
    </div>
  );
};

export default SoundLicenseListTable;
