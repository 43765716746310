import { isEmpty } from 'lodash';

export const safeJsonParse = (rawValue: string | null, fallbackValue = null): unknown => {
  let value;

  try {
    value = JSON.parse(rawValue as string);
  } catch (e: unknown) {
    value = null;
  }

  return isEmpty(value) ? fallbackValue : value;
};
