import React from 'react';

type Props = {
  text: string;
};

const ErrorMessageText = ({ text }: Props) => {
  return <div className="error text-danger py-2 text-center">{text}</div>;
};

export default ErrorMessageText;
