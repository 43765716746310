import React from 'react';
import ControlledFormDeleteButton from 'components/forms/ControlledFormDeleteButton';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import BirdsWithSoundLicenseModal from './BirdsWithSoundLicenseModal';
import { Button } from 'react-bootstrap';
import useToggle from 'hooks/useToggle';
import { useNavigate } from 'react-router';
import useSoundLicenseRemove from 'hooks/soundLicense/useSoundLicenseRemove';
import { BirdFragmentFragment, SoundLicenseFieldsFragment } from 'apollo/graphql/generated';

type SoundLicenseFormDeleteButtonProps = {
  soundLicense?: SoundLicenseFieldsFragment;
};

const SoundLicenseFormDeleteButton = ({ soundLicense }: SoundLicenseFormDeleteButtonProps) => {
  const [birdsWithSoundLicense, setBirdsWithSoundLicense] = React.useState<readonly BirdFragmentFragment[]>([]);
  const { removeOperation, removeSoundLicense } = useSoundLicenseRemove();
  const [showRemoveConfirmationModal, toggleShowRemoveConfirmationModal] = useToggle();
  const [showBirdsWithSoundLicenseModal, toggleShowBirdsWithSoundLicenseModal] = useToggle();

  const navigate = useNavigate();

  if (!soundLicense) {
    return null;
  }

  const onDeleteConfirmed = async () => {
    const [numberOfRemovedItems, foundBirdsWithSoundLicense] = await removeSoundLicense(soundLicense);

    toggleShowRemoveConfirmationModal();

    if (numberOfRemovedItems) {
      navigate('/app/birds/licenses');
    } else if (foundBirdsWithSoundLicense.length) {
      setBirdsWithSoundLicense(foundBirdsWithSoundLicense);
      toggleShowBirdsWithSoundLicenseModal();
    }
  };

  const onClick = () => {
    if (birdsWithSoundLicense.length > 0) {
      toggleShowBirdsWithSoundLicenseModal();
    } else {
      toggleShowRemoveConfirmationModal();
    }
  };

  return (
    <>
      <ControlledFormDeleteButton onClick={onClick}>Delete license</ControlledFormDeleteButton>
      <ConfirmationModal
        show={showRemoveConfirmationModal}
        title="Confirm language delete"
        confirmationHandler={onDeleteConfirmed}
        closeHandler={toggleShowRemoveConfirmationModal}
        ConfirmationButton={
          <Button variant="danger" onClick={onDeleteConfirmed} disabled={removeOperation}>
            Remove this license
          </Button>
        }
      >
        Are you sure you want to delete <strong>{soundLicense.name}</strong>?
      </ConfirmationModal>
      {birdsWithSoundLicense.length > 0 && showBirdsWithSoundLicenseModal && (
        <BirdsWithSoundLicenseModal
          soundLicense={soundLicense}
          birds={birdsWithSoundLicense}
          onClose={toggleShowBirdsWithSoundLicenseModal}
        />
      )}
    </>
  );
};

export default SoundLicenseFormDeleteButton;
