import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { useAlerts } from 'hooks/useAlerts';
import React from 'react';
import { AnyObjectSchema } from 'yup';
import ControlledCustomFormChangePrompt from './ControlledCustomFormChangePrompt';
import ControlledCustomFormSubmit from './CustomFormSubmit';

const defaultInitialValues = {};

const CustomFormDebug = () => {
  const { values } = useFormikContext();

  return <pre>{JSON.stringify(values)}</pre>;
};

type CustomFormRenderFunctionType<T = unknown> = (props: { values: T }) => React.ReactNode;
type CustomFormProps<T = unknown> = {
  children: CustomFormRenderFunctionType<T> | React.ReactNode;
  debug?: boolean;
  initialValues?: T;
  /**
   * A Yup Schema or a function that returns a Yup schema
   */
  validationSchema?: AnyObjectSchema | (() => AnyObjectSchema);
  onSubmit?: (formValues: T) => void | Promise<unknown>;
};

function CustomForm<T = unknown>({ children, debug, initialValues, validationSchema, onSubmit }: CustomFormProps<T>) {
  const { addErrorAlert } = useAlerts();
  const onFormSubmit = async (formValues: T, formikHelpers: FormikHelpers<T>) => {
    if (onSubmit) {
      const submitResult = await onSubmit(formValues);

      formikHelpers.resetForm({
        values: formValues
      });

      return submitResult;
    }
  };

  const onFormSubmitError = () => {
    addErrorAlert({
      message: 'Form validation error. Check for input errors and try again.'
    });
  };

  if (typeof children === 'function') {
    return (
      <Formik
        initialValues={initialValues ?? defaultInitialValues}
        onSubmit={onFormSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form noValidate>
            <ControlledCustomFormSubmit onSubmitError={onFormSubmitError} />
            <ControlledCustomFormChangePrompt />
            {debug && <CustomFormDebug />}
            {(children as CustomFormRenderFunctionType)(props)}
          </Form>
        )}
      </Formik>
    );
  }

  return (
    <Formik
      initialValues={initialValues ?? defaultInitialValues}
      onSubmit={onFormSubmit}
      validationSchema={validationSchema}
    >
      <Form noValidate>
        <ControlledCustomFormSubmit onSubmitError={onFormSubmitError} />
        <ControlledCustomFormChangePrompt />
        {debug && <CustomFormDebug />}
        {children}
      </Form>
    </Formik>
  );
}

export default CustomForm;
