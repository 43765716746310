/* eslint-disable react/jsx-key */
import React, { useContext } from 'react';
import type { FC } from 'react';

import TableContext from '../../../contexts/TableContext';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

const Nav = styled.nav`
  z-index: 0;
`;

const TablePagination: FC = () => {
  const { setPagination, pagination, isInitialized } = useContext(TableContext);

  if (!isInitialized) return null;
  return (
    <Nav aria-label="pagination">
      <ReactPaginate
        pageCount={pagination.lastPage}
        forcePage={pagination.currentPage - 1}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={(page) => setPagination({ ...pagination, currentPage: page.selected + 1 })}
        containerClassName="pagination"
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
        pageLinkClassName="page-link"
        activeClassName="active"
        disabledClassName="disabled"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        nextLabel={<>&raquo;</>}
        previousLabel={<>&laquo;</>}
      />
    </Nav>
  );
};

export default TablePagination;
