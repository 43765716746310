import React, { ChangeEventHandler } from 'react';
import { Form } from 'react-bootstrap';

export type FormControlType = 'text';

type CustomFormInputProps = {
  disabled?: boolean;
  isInvalid?: boolean;
  name?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  type?: FormControlType;
  value?: string;
};

const CustomFormInput = ({
  disabled,
  isInvalid,
  name,
  onChange,
  placeholder,
  type = 'text',
  value
}: CustomFormInputProps) => {
  return (
    <Form.Control
      disabled={disabled}
      isInvalid={isInvalid}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      type={type}
      value={value || ''}
    />
  );
};

export default CustomFormInput;
