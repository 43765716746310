import React from 'react';
import styled from 'styled-components';

const StyledText = styled.h2`
  font-family: ${({ theme: { fonts } }) => fonts.helveticaNeue};
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  color: ${(props) => props.color || props.theme.colors.global.primary1};
  @media (max-width: ${(props) => props.theme.deviceSize.mobileL}) {
    font-size: 28px;
  }
`;

type TextProps = {
  color?: string;
};

const H2 = ({ children, color }: React.PropsWithChildren<TextProps>) => {
  return <StyledText color={color}>{children}</StyledText>;
};

export default H2;
