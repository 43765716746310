import React, { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

type Props = {
  images: string[];
  open: boolean;
  setOpen: (open: boolean) => void;
  openedImageIndex: number;
};

const ImagesGallery = ({ images, open, setOpen, openedImageIndex }: Props) => {
  const [photoIndex, setPhotoIndex] = useState(0);

  useEffect(() => {
    setPhotoIndex(openedImageIndex);
  }, [openedImageIndex]);

  return (
    <>
      {open && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          imagePadding={100}
          reactModalStyle={{ overlay: { zIndex: 10000 } }}
        />
      )}
    </>
  );
};

export default ImagesGallery;
