import React, { PropsWithChildren } from 'react';
import { ThemeProvider } from 'styled-components';
import { deviceSize } from './constants/sizes';

const theme = {
  colors: {
    text: {
      body1: '#1E1E1E',
      muted2: '#8F8F8F'
    },
    global: {
      primary1: '#233869'
    },
    background: {
      danger4: '#F8D7DA',
      success3: '#D4EDDA'
    },
    forms: {
      focusLine: '#919BB4'
    },
    border: {
      outlineSecondary: '#6c757d'
    }
  },
  fonts: {
    helveticaNeue: 'Helvetica Neue'
  },
  fontWeights: {
    regular: 400,
    bold: 700
  },
  deviceSize: deviceSize
};

const Theme = ({ children }: PropsWithChildren<unknown>) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

export default Theme;
export type ThemeType = typeof theme;
