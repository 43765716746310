import { useReportedMediaSummaryQuery } from '../../apollo/graphql/generated';
import useGraphError from '../useGraphError';

const useReportedMedia = (id: string) => {
  const { data, loading, error } = useReportedMediaSummaryQuery({ variables: { id: id } });

  const errorMessage = useGraphError(error, loading, data?.mediaReportSummary);

  const reportedMediaItem =
    data?.mediaReportSummary.__typename === 'MediaReportSummaryModel' ? data.mediaReportSummary : undefined;

  return { reportedMediaItem, loading, errorMessage };
};

export default useReportedMedia;
