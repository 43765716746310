import React from 'react';
import ReportedPhotoDetailsHeader from 'components/reportedPhotos/ReportedPhotoDetailsHeader';
import ReportedPhotosDetailsCard from 'components/reportedPhotos/ReportedPhotoDetailsCard';
import ReportedPhotosHistoryCard from 'components/reportedPhotos/ReportedPhotoHistoryCard';
import { useParams } from 'react-router';
import useReportedMedia from 'hooks/reportedMedia/useReportedMedia';
import LoadingScreen from 'components/LoadingScreen';
import NotFound from 'pages/NotFound';

const ReportedPhotoDetails = () => {
  const { id } = useParams();
  const { reportedMediaItem, loading, errorMessage } = useReportedMedia(id);

  return (
    <div className="container">
      <div className="page">
        {loading && <LoadingScreen />}
        {!!errorMessage && <NotFound />}
        {!!reportedMediaItem && (
          <>
            <ReportedPhotoDetailsHeader
              nextItem={reportedMediaItem.nextMediaReport}
              previousItem={reportedMediaItem.previousMediaReport}
              totalCount={reportedMediaItem.totalCount}
              resolved={!!reportedMediaItem.currentMediaReport.decision}
            />
            <ReportedPhotosDetailsCard
              reportedMediaItem={reportedMediaItem.currentMediaReport}
              nextItem={reportedMediaItem.nextMediaReport}
            />
            <ReportedPhotosHistoryCard reportedMediaId={reportedMediaItem.currentMediaReport.media.id} />
          </>
        )}
      </div>
    </div>
  );
};

export default ReportedPhotoDetails;
