import React from 'react';
import PageHeader from '../../components/PageHeader';
import { useParams } from 'react-router-dom';
import NotFound from '../NotFound';
import { SoundLicenseCreateInput, useSoundLicenseQuery } from 'apollo/graphql/generated';
import useSoundLicenseEdit from 'hooks/soundLicense/useSoundLicenseEdit';
import SoundLicenseForm from 'components/soundLicenses/SoundLicenseForm';
import BackButton from 'components/buttons/BackButton';
import PageHead from 'components/PageHead';

const EditSoundLicense = () => {
  const { id } = useParams();

  const { data, loading } = useSoundLicenseQuery({
    variables: {
      id
    }
  });
  const { editSoundLicense } = useSoundLicenseEdit();

  const onSoundLicenseSubmit = React.useCallback(
    async (input: SoundLicenseCreateInput) => {
      await editSoundLicense(id, input);
    },
    [id, editSoundLicense]
  );

  if (loading) {
    return null;
  }

  if (!data?.soundLicense || data.soundLicense.__typename === 'Problem') {
    return <NotFound />;
  }

  const soundLicense = data.soundLicense;

  return (
    <>
      <PageHead title={soundLicense.name} />
      <div className="page">
        <PageHeader
          title={soundLicense.name}
          backButton={<BackButton label="All licenses" to="/app/birds/licenses" />}
        />
        <div className="container">
          <SoundLicenseForm entity={data?.soundLicense} onSubmit={onSoundLicenseSubmit} />
        </div>
      </div>
    </>
  );
};

export default EditSoundLicense;
