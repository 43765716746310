import H2 from 'components/typography/H2';
import React, { PropsWithChildren } from 'react';

interface CardProps {
  title?: string;
}

const Card = ({ children, title }: PropsWithChildren<CardProps>) => {
  return (
    <div className="d-flex p-3 row border mt-5">
      {title && <H2>{title}</H2>}
      {children}
    </div>
  );
};

export default Card;
