import React, { PropsWithChildren } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

type FormGroupWrapperProps = {
  hasMarginBottom: boolean;
};

const FormGroupWrapper = styled.div<FormGroupWrapperProps>`
  margin-bottom: ${(props) => (props.hasMarginBottom ? '30px' : 0)};
  width: 100%;
`;

type CustomFormGroupProps = {
  className?: string;
  controlId?: string;
  label?: string | JSX.Element;
  helper?: string | JSX.Element;
  marginBottom?: boolean;
};

const CustomFormGroup = ({
  className,
  children,
  label,
  helper,
  marginBottom = true,
  controlId
}: PropsWithChildren<CustomFormGroupProps>) => {
  return (
    <FormGroupWrapper hasMarginBottom={!className && marginBottom}>
      <Form.Group className={className} controlId={controlId}>
        {!!label && <Form.Label>{label}</Form.Label>}
        {children}
        {!!helper && <Form.Text muted>{helper}</Form.Text>}
      </Form.Group>
    </FormGroupWrapper>
  );
};

export default CustomFormGroup;
