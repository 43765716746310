import React from 'react';
import styled from 'styled-components';

const StyledText = styled.p`
  font-family: ${({ theme: { fonts } }) => fonts.helveticaNeue};
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: ${(props) => props.color || props.theme.colors.text.muted2};
  margin: 0px;
  @media (max-width: ${(props) => props.theme.deviceSize.mobileL}) {
    font-size: 11px;
  }
`;

type TextProps = {
  color?: string;
};

const BodySmallText = ({ children, color }: React.PropsWithChildren<TextProps>) => {
  return <StyledText color={color}>{children}</StyledText>;
};

export default BodySmallText;
