import React from 'react';
import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../auth/useAuth';

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { loggedIn, authInitialized } = useAuth();

  if (!authInitialized) {
    return null;
  }

  if (!loggedIn) {
    return (
      <Navigate
        to={`/?redirectUrl=${encodeURIComponent(document.location.href.replace(document.location.origin, ''))}`}
      />
    );
  }

  return <>{children}</>;
};

export default AuthGuard;
