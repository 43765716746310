import { useCallback, useContext } from 'react';

import { AlertsContext } from 'contexts/AlertsProvider/AlertsProvider';
import { AlertMessage } from 'contexts/AlertsProvider/types';

export type UseAlertsType = {
  (): {
    addErrorAlert: (alertMessage: AlertMessage) => void;
    addSuccessAlert: (alertMessage: AlertMessage) => void;
    addCustomAlert: (alertMessage: AlertMessage) => void;
  };
};

export const useAlerts: UseAlertsType = () => {
  const { addAlert } = useContext(AlertsContext);

  const addErrorAlert = useCallback(
    (alertMessage: AlertMessage) => {
      return addAlert({
        ...alertMessage,
        alertType: 'danger'
      });
    },
    [addAlert]
  );

  const addSuccessAlert = useCallback(
    (alertMessage: AlertMessage) => {
      return addAlert({
        ...alertMessage,
        alertType: 'success'
      });
    },
    [addAlert]
  );

  const addCustomAlert = useCallback(
    (alertMessage: AlertMessage) => {
      return addAlert({
        ...alertMessage,
        alertType: 'custom'
      });
    },
    [addAlert]
  );

  return {
    addAlert,
    addErrorAlert,
    addSuccessAlert,
    addCustomAlert
  };
};
